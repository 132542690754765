import { CurrencyName } from '~/constants/currency.constants';
import type { TCurrencyType } from '~/types/Shared.types';
import { getValueRoundedByCurrency } from '~/utils/currency.utils';

interface IPriceOptions {
  animationOptions?: anime.AnimeParams;
  customCurrency?: TCurrencyType;
}

export const usePrice = (value: Ref<string | number>, { animationOptions, customCurrency }: IPriceOptions) => {
  const { defaultCurrency } = useProjectSettings();

  const animatedValue = ref<number>(0);

  const usingCurrency = computed(() => customCurrency || (defaultCurrency as TCurrencyType));

  const formattedValue = computed(() => getValueRoundedByCurrency(animatedValue.value) ?? 0);

  watch(
    value,
    (newValue) => {
      const newValueNumber = Number(newValue);

      if (!animationOptions || import.meta.server) {
        animatedValue.value = newValueNumber;
        return;
      }

      useAnime({
        ...animationOptions,
        round: isNeedToRoundAnimation(usingCurrency.value),
        targets: animatedValue,
        value: newValueNumber,
      });
    },
    { immediate: true },
  );

  return {
    formattedValue,
  };
};

// Требуется ли округление в Anime.js
const isNeedToRoundAnimation = (currency: TCurrencyType): boolean => {
  return currency !== CurrencyName.USD;
};
